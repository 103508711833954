<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">TELLER TRANSACTIONS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout row wrap class="pt-6">
          <v-flex xs12 md6>
            <v-select
              v-model="month_of"
              class="mx-3 pt-5"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              @change="get_teller_transactions_items"
              :rules="rules.combobox_rule"
            ></v-select>

          </v-flex>
          <v-flex xs12 md6 class="pt-6">
            <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
          </v-flex>
          <v-flex xs12 md12>
            <teller-dashboard-funds-card
              :teller_fund_data="teller_fund_data"
              :month_of="month_of_items[month_of_items.map(function (x) {
            return x.id
          }).indexOf(month_of)]"
              :fund_amount="fund_amount"
              :cash_on_hand="cash_on_hand"
              :total_deposits="total_deposits"
              :total_withdrews="total_withdrews"
            ></teller-dashboard-funds-card>
          </v-flex>
          <v-flex xs12 md12>
            <teller-dashboard-card-deposit-and-withdraw
              v-on:data="on_changes_of_void"
              :deposit="deposit"
              :withdraw="withdraw"
              :deposit_share="deposit_share"
              :withdraw_share="withdraw_share"
              :deposit_time="deposit_time"
              :withdraw_time="withdraw_time"
              :deposit_passbook="deposit_passbook"
              :withdraw_passbook="withdraw_passbook"
            ></teller-dashboard-card-deposit-and-withdraw>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'
  import TellerDashboardCardDepositAndWithdraw
    from './teller_transaction_components/TellerCardDepositAndWithdraw'
  import TellerDashboardFundsCard from './../dashboard/teller_interface/TellerDashboardFundsCard'

  export default {
    components: {
      TellerDashboardFundsCard,
      TellerDashboardCardDepositAndWithdraw,
    },
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        today_time: '',
        deposit: [],
        withdraw: [],
        deposit_share: [],
        withdraw_share: [],
        deposit_time: [],
        withdraw_time: [],
        deposit_passbook: [],
        withdraw_passbook: [],
        teller_fund_data: [],
        total_deposits: '0',
        total_withdrews: '0',
        fund_amount: '0',
        cash_on_hand: '0',


        month_of: '',
        can_print: false,
        list_of_teller_transactions: [],
        month_of_items: [],
      }
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.retrieve_transaction_month(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.month_of = ''
        this.deposit = []
        this.withdraw = []
        this.deposit_share = []
        this.withdraw_share = []
        this.deposit_time = []
        this.withdraw_time = []
        this.deposit_passbook = []
        this.withdraw_passbook = []
        this.list_of_teller_transactions = []
        this.teller_fund_data = []
        this.fund_amount = '0'
        this.cash_on_hand = '0'
        this.total_deposits = '0'
        this.total_withdrews = '0'
      }
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      ...mapActions('teller_fund', ['teller_monthly_transaction_data']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      on_changes_of_void() {
        this.deposit = []
        this.withdraw = []
        this.deposit_share = []
        this.withdraw_share = []
        this.deposit_time = []
        this.withdraw_time = []
        this.deposit_passbook = []
        this.withdraw_passbook = []
        this.list_of_teller_transactions = []
        this.teller_fund_data = []
        this.fund_amount = '0'
        this.cash_on_hand = '0'
        this.total_deposits = '0'
        this.total_withdrews = '0'
        this.get_teller_transactions_items()
      },
      get_teller_transactions_items() {
        if (this.month_of_items.length > 0) {
          var index = this.month_of_items.map(function (x) {
            return x.id
          }).indexOf(this.month_of)
          if (index != -1) {
            this.data_table_loading = true
            const data = new FormData()
            data.append('branch_id', this.branch_id);
            data.append('month_of', this.month_of_items[index].month_of);
            this.teller_monthly_transaction_data(data)
              .then(response => {
                this.can_print = true
                this.deposit = response.data[0].deposit
                this.withdraw = response.data[0].withdraw
                this.deposit_share = response.data[0].deposit_share
                this.withdraw_share = response.data[0].withdraw_share
                this.deposit_time = response.data[0].deposit_time
                this.withdraw_time = response.data[0].withdraw_time
                this.deposit_passbook = response.data[0].deposit_passbook
                this.withdraw_passbook = response.data[0].withdraw_passbook
                this.teller_fund_data = response.data[0].fund_data
                this.total_deposits = response.data[0].total_deposits
                this.total_withdrews = response.data[0].total_withdrews
                this.fund_amount = response.data[0].fund
                this.cash_on_hand = response.data[0].cash_on_hand
                this.list_of_teller_transactions = response.data[0].teller_transactions
                this.data_table_loading = false
              })
              .catch(error => {
                this.data_table_loading = false
              })
          }
        }
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)

        var month = this.month_of_items[indexMonth].month_of
        var total_dep = this.total_deposits
        var total_withdrawals = this.total_withdrews

        var fund = this.fund_amount
        var cash_on_hand = this.cash_on_hand
        array.push(
          [
            {text: 'Date', alignment: 'center', style: 'label'},
            {text: 'Deposits', alignment: 'center', style: 'label'},
            {text: 'Withdrawals', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_teller_transactions.length > 0) {
          this.list_of_teller_transactions.forEach(function (item, index, payment) {
            array.push(
              [
                {text: item.date, alignment: 'center'},
                {text: item.deposits, alignment: 'left'},
                {text: item.withdrawals, alignment: 'left'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              array.push(
                [
                  {
                    color: 'red',
                    text: 'TOTAL: ',
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_dep,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_withdrawals,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                ]
              )
            }
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'TELLER TRANSACTIONS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            {text: 'FUND: ' + fund, style: 'main_info', color: 'blue',},
            {text: 'CASH ON HAND: ' + cash_on_hand, style: 'main_info', color: 'red',},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [100, 100, 100],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
