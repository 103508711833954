<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-toolbar dense dark color="accent">
        <v-toolbar-title><h4 class="font-weight-light" v-if="!is_void">REGULAR SAVINGS DEPOSIT</h4>
          <h4 class="font-weight-light" v-else>VOIDING SAVINGS DEPOSIT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="8"
              >
                <v-text-field
                  v-if="is_void"
                  v-model="from_account_name"
                  label="From Account Name"
                  dense
                  outlined
                  readonly
                ></v-text-field>
                <v-combobox
                  :append-icon="icons.mdiAccountSearchOutline"
                  v-model="search"
                  @keyup="searching($event.target.value)"
                  @change="get_search_items_info"
                  :items="search_items"
                  item-value="id"
                  item-text="account_name"
                  placeholder="Search by (Account Name/Account #)"
                  ref="memberCombobox"
                  dense
                  outlined
                ></v-combobox>
                <v-text-field
                  v-model="account_no"
                  label="Account No"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="account_name"
                  label="Account Name"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model="status"
                  label="Status"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
                <v-col></v-col>
                <v-row>
                  <!--                  <v-col-->
                  <!--                    cols="12"-->
                  <!--                    md="6"-->
                  <!--                  >-->
                  <!--                    <v-text-field-->
                  <!--                      v-model="balance_num"-->
                  <!--                      label="Balance"-->
                  <!--                      dense-->
                  <!--                      outlined-->
                  <!--                      :rules="rules.default_max_45_character_and_no_empty_rule"-->
                  <!--                      readonly-->
                  <!--                    ></v-text-field>-->
                  <!--                  </v-col>-->
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-if="!is_void"
                      v-model="amount"
                      label="Amount to Deposit"
                      dense
                      outlined
                      type="number"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                    <v-text-field
                      readonly
                      v-else
                      v-model="amount"
                      label="Amount to Deposit"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      readonly
                      v-model="charges_dep"
                      label="Charges"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-if="!is_void"
                      v-model="depositors_name"
                      label="Depositors Name"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                    <v-text-field
                      readonly
                      v-else
                      v-model="depositors_name"
                      label="Depositors Name"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="3"
                class="d-none d-sm-flex justify-center position-relative"
              >
                <v-img
                  contain
                  max-width="170"
                  src="@/assets/images/3d-characters/pose-m-1.png"
                  class="security-character"
                ></v-img>
              </v-col>
            </v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_deposit_slips"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import fpPromise from '@fingerprintjs/fingerprintjs'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    props: {
      is_void: Boolean,
      account_noooo: String,
      amounttt: Number,
      depositorrrr: String,
      prop_value: Object,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],
        balance: 0,
        balance_num: 0,

        account_id: '',
        account_no: '',
        account_name: '',
        amount: '0',
        charges_dep: '0',
        depositors_name: '',

        status: '',
        from_account_name: '',
      }
    },
    mounted() {
      if (this.is_void) {
        this.amount = this.amounttt
        this.depositors_name = this.depositorrrr
        this.from_account_name = this.prop_value.savings_info.account_name
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id', 'user_id', 'branch', 'month_start', 'month_end', 'month_of']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('regular_savings_info', ['search_members_have_savings_account']),
      ...mapActions('regular_savings_data', ['register_savings_deposit']),
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.search = ''
        this.search_member = ''
        this.search_items = []

        this.account_id = 0
        this.account_no = ''
        this.account_name = ''
        // this.balance = 0
        // this.balance_num = 0
        //
        // this.amount = 0
        this.status = ''
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_members_have_savings_account({
          search_word: value,
          active: 0,
          branch_id: this.branch_id,
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.account_id = this.search_items[index].id
            this.account_no = this.search_items[index].account_no
            this.account_name = this.search_items[index].account_name
            this.balance = this.search_items[index].balance
            this.balance_num = this.search_items[index].balance_num
            this.status = this.search_items[index].status
            this.charges_dep = this.search_items[index].is_have_charges ? this.search_items[index].charges + '' : 0 + ''
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },

      useNavigatorInfo() {
        const userAgent = navigator.userAgent.toLowerCase()
        const Android = userAgent.indexOf('android') > -1
        let os_main = 'Windows'

        if (navigator.appVersion.indexOf("Win") != -1) {
          os_main = 'Windows'
        }

        if (navigator.appVersion.indexOf("Mac") != -1) {
          os_main = 'Mac'
        }

        if (navigator.appVersion.indexOf("X11") != -1) {
          os_main = 'UNIX'
        }

        if (navigator.appVersion.indexOf("Linux") != -1) {
          os_main = 'Linux'
        }

        if (Android) {
          os_main = 'Android'
        }

        return {
          os_main: os_main
        }
      },
      save_deposit_slips() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          fpPromise.load()
            .then(fp => fp.get())
            .then(result => {
              // This is the visitor identifier:
              const navi = this.useNavigatorInfo()
              const fpDetails = {
                deviceMemory: result.components.deviceMemory.value,
                cpuThreads: result.components.hardwareConcurrency.value,
                os: navi.os_main,
                gpu: result.components.webGlBasics.value.rendererUnmasked,
                username: '',
              }
              const data = new FormData()
              var datweee = moment().format('MMMM DD, YYYY');
              var tiemee = moment().format('hh:mm:ss');
              data.append('branch_id', this.branch_id);
              data.append('teller_id', this.user_id);
              data.append('regular_savings_info_id', this.account_id);
              data.append('deposit', this.amount);
              data.append('inter_branch_charges', this.charges_dep);
              data.append('depositors_name', this.depositors_name);
              data.append('month_of', this.month_of);
              data.append('last_balance', this.balance);
              data.append('date', datweee);
              data.append('time', tiemee);
              data.append('device_os', fpDetails.os);
              data.append('device_gpu', fpDetails.gpu);
              data.append('from_loam', 0);
              data.append('id', 'null');
              var proceed = false
              if (this.is_void) {
                data.append('id', this.prop_value.id);
                proceed = true
              } else {
                proceed = true
              }
              if (proceed) {
                this.register_savings_deposit(data)
                  .then(response => {
                    if (response.status === 201) {
                      this.alert = true
                      this.alert_message = response.data
                      this.saving = false
                    } else {

                      if (this.is_void) {
                        this.change_snackbar({
                          show: true,
                          color: 'success',
                          text: 'SUCCESSFULLY DEPOSITED TO THE RIGHT ACCOUNT!!!',
                        })
                        this.$emit('data')
                      } else {
                        this.print_deposit_slip(response.data.id, response.data.date, response.data.time, response.data.regular_savings_info_id)
                        this.change_snackbar({
                          show: true,
                          color: 'success',
                          text: 'SUCCESSFULLY DEPOSITED!!!',
                        })
                      }
                      this.reset()
                    }
                  })
                  .catch(error => {
                    this.alert = true
                    this.alert_message = error
                    this.saving = false
                  })
              }
            })
            .catch(() => {
              alert('Error upon validation!')
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      print_deposit_slip(id, dateeee, time, reg_id) {
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {text: 'Savings Acct, Cash Deposit', style: 'printed_label'},
            {text: 'REFDEP' + id, style: 'printed_label'},
            {text: this.account_no, style: 'printed_label'},
            {text: this.account_name, style: 'printed_label'},
            {text: 'PHP ' + this.amount, style: 'printed_label'},
            {text: 'SAVINGS DEPOSIT', style: 'printed_label'},
            {text: 'INTER BRANCH CHARGES', style: 'printed_label'},
            {text: 'PHP ' + this.charges_dep, style: 'printed_label'},
            {text: dateeee + ' ' + time, style: 'printed_label'},
            {text: 'RGST' + reg_id, style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'printed_label'},
            {text: 'Depositor: ' + this.depositors_name.toUpperCase(), style: 'printed_label'},
          ],
          styles: {
            printed_label: {
              color: 'red',
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
          }
        }
        pdfMake.createPdf(docDefinition).open();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .two-factor-auth {
    max-width: 25rem;
  }

  .security-character {
    position: absolute;
    bottom: -0.5rem;
  }
</style>
