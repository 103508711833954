<template>
  <div>
    <v-tabs
      v-model="tab_t"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs_t"
        :key="tab.title"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>
    <!-- tabs item -->
    <v-tabs-items v-model="tab_t">
      <v-tab-item>
        <v-card>
          <div class="d-flex flex-sm-row flex-column">
            <div class="flex-grow-1">
              <v-card-title>
                <span class="me-3">Deposits</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(data,index) in deposit"
                    :key="data.date"
                    :class="`d-flex px-0 ${index > 0 ? 'mt-0':''}`"
                  >
                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div class="me-auto pe-2">
                        <h4 class="font-weight-semibold">
                          {{ data.date }}
                        </h4>
                      </div>

                      <span class="font-weight-semibold success--text cursor-pointer"
                            @click="selected_data_deposit(deposit.map(function(x) {return x.date; }).indexOf(data.date))"> {{ data.amount }}</span>
                      &nbsp;
                      <div v-if="data.amount!='+P 0.00'">
                        <div v-if="data.done > 0">
                    <span class="font-weight-semibold error--text cursor-pointer"
                          @click="is_pending_click(deposit.map(function(x) {return x.date; }).indexOf(data.date))">PENDING</span>
                        </div>
                        <div v-else>
                          <span class="font-weight-semibold warning--text">GOOD</span>
                        </div>
                      </div>

                    </div>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>

            <v-divider
              class="my-sm-5 mx-5"
              :vertical="$vuetify.breakpoint.smAndUp"
            ></v-divider>

            <div class="flex-grow-1">
              <v-card-title>
                <span class="me-3">Withdrawals</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(data,index) in withdraw"
                    :key="data.date"
                    :class="`d-flex px-0 ${index > 0 ? 'mt-0':''}`"
                  >

                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div class="me-auto pe-2">
                        <h4 class="font-weight-semibold">
                          {{ data.date }}
                        </h4>
                      </div>

                      <span class="font-weight-semibold error--text cursor-pointer"
                            @click="selected_data_withdrawal(withdraw.map(function(x) {return x.date; }).indexOf(data.date))">{{ data.amount }}</span>
                    </div>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <div class="d-flex flex-sm-row flex-column">
            <div class="flex-grow-1">
              <v-card-title>
                <span class="me-3">Deposits</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(data,index) in deposit_share"
                    :key="data.date"
                    :class="`d-flex px-0 ${index > 0 ? 'mt-0':''}`"
                  >
                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div class="me-auto pe-2">
                        <h4 class="font-weight-semibold">
                          {{ data.date }}
                        </h4>
                      </div>

                      <span class="font-weight-semibold success--text cursor-pointer"
                            @click="selected_data_deposit_share(deposit.map(function(x) {return x.date; }).indexOf(data.date))"> {{ data.amount }}</span>
                      &nbsp;
                      <div v-if="data.amount!='+P 0.00'">
                        <div v-if="data.done > 0">
                    <span class="font-weight-semibold error--text cursor-pointer"
                          @click="is_pending_click(deposit.map(function(x) {return x.date; }).indexOf(data.date))">PENDING</span>
                        </div>
                        <div v-else>
                          <span class="font-weight-semibold warning--text">GOOD</span>
                        </div>
                      </div>

                    </div>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>

            <v-divider
              class="my-sm-5 mx-5"
              :vertical="$vuetify.breakpoint.smAndUp"
            ></v-divider>

            <div class="flex-grow-1">
              <v-card-title>
                <span class="me-3">Withdrawals</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(data,index) in withdraw_share"
                    :key="data.date"
                    :class="`d-flex px-0 ${index > 0 ? 'mt-0':''}`"
                  >

                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div class="me-auto pe-2">
                        <h4 class="font-weight-semibold">
                          {{ data.date }}
                        </h4>
                      </div>

                      <span class="font-weight-semibold error--text cursor-pointer"
                            @click="selected_data_withdrawal_share(withdraw.map(function(x) {return x.date; }).indexOf(data.date))">{{ data.amount }}</span>
                    </div>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <div class="d-flex flex-sm-row flex-column">
            <div class="flex-grow-1">
              <v-card-title>
                <span class="me-3">Deposits</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(data,index) in deposit_time"
                    :key="data.date"
                    :class="`d-flex px-0 ${index > 0 ? 'mt-0':''}`"
                  >
                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div class="me-auto pe-2">
                        <h4 class="font-weight-semibold">
                          {{ data.date }}
                        </h4>
                      </div>

                      <span class="font-weight-semibold success--text cursor-pointer"
                            @click="selected_data_deposit_time(deposit.map(function(x) {return x.date; }).indexOf(data.date))"> {{ data.amount }}</span>
                      &nbsp;
                      <div v-if="data.amount!='+P 0.00'">
                        <div v-if="data.done > 0">
                    <span class="font-weight-semibold error--text cursor-pointer"
                          @click="is_pending_click(deposit.map(function(x) {return x.date; }).indexOf(data.date))">PENDING</span>
                        </div>
                        <div v-else>
                          <span class="font-weight-semibold warning--text">GOOD</span>
                        </div>
                      </div>

                    </div>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>

            <v-divider
              class="my-sm-5 mx-5"
              :vertical="$vuetify.breakpoint.smAndUp"
            ></v-divider>

            <div class="flex-grow-1">
              <v-card-title>
                <span class="me-3">Withdrawals</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(data,index) in withdraw_time"
                    :key="data.date"
                    :class="`d-flex px-0 ${index > 0 ? 'mt-0':''}`"
                  >

                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div class="me-auto pe-2">
                        <h4 class="font-weight-semibold">
                          {{ data.date }}
                        </h4>
                      </div>

                      <span class="font-weight-semibold error--text cursor-pointer"
                            @click="selected_data_withdrawal_time(withdraw.map(function(x) {return x.date; }).indexOf(data.date))">{{ data.amount }}</span>
                    </div>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <div class="d-flex flex-sm-row flex-column">
            <div class="flex-grow-1">
              <v-card-title>
                <span class="me-3">Deposits</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(data,index) in deposit_passbook"
                    :key="data.date"
                    :class="`d-flex px-0 ${index > 0 ? 'mt-0':''}`"
                  >
                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div class="me-auto pe-2">
                        <h4 class="font-weight-semibold">
                          {{ data.date }}
                        </h4>
                      </div>

                      <span class="font-weight-semibold success--text cursor-pointer"
                            @click="selected_data_deposit_passbook(deposit.map(function(x) {return x.date; }).indexOf(data.date))"> {{ data.amount }}</span>
                      &nbsp;
                      <div v-if="data.amount!='+P 0.00'">
                        <div v-if="data.done > 0">
                    <span class="font-weight-semibold error--text cursor-pointer"
                          @click="is_pending_click(deposit.map(function(x) {return x.date; }).indexOf(data.date))">PENDING</span>
                        </div>
                        <div v-else>
                          <span class="font-weight-semibold warning--text">GOOD</span>
                        </div>
                      </div>

                    </div>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>

            <v-divider
              class="my-sm-5 mx-5"
              :vertical="$vuetify.breakpoint.smAndUp"
            ></v-divider>

            <div class="flex-grow-1">
              <v-card-title>
                <span class="me-3">Withdrawals</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <v-list>
                  <v-list-item
                    v-for="(data,index) in withdraw_passbook"
                    :key="data.date"
                    :class="`d-flex px-0 ${index > 0 ? 'mt-0':''}`"
                  >

                    <div class="d-flex align-center flex-grow-1 flex-wrap">
                      <div class="me-auto pe-2">
                        <h4 class="font-weight-semibold">
                          {{ data.date }}
                        </h4>
                      </div>

                      <span class="font-weight-semibold error--text cursor-pointer"
                            @click="selected_data_withdrawal_passbook(withdraw.map(function(x) {return x.date; }).indexOf(data.date))">{{ data.amount }}</span>
                    </div>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="remittances_dialog" width="80%">
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <payments
            :key="key"
            :savings_date="savings_date"></payments>
        </v-tab-item>
        <v-tab-item>
          <deposit-slip
            :key="key"
          ></deposit-slip>
        </v-tab-item>
      </v-tabs-items>
    </v-dialog>
    <v-dialog :key="key" v-model="teller_transaction_data" width="80%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">TELLER TRANSACTIONS DATA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">{{title}}</h4>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <span class="headline font-weight-light white--text"
                v-if="teller_data.length>0">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_selected_deposit_slip_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
        </v-toolbar>
        <v-data-table v-if="is_savings" dense
                      :headers="headers_teller"
                      :items="teller_data"
                      :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>

              <v-text-field
                class="mx-2"
                :append-icon="icons.mdiAccountSearchOutline"
                label="Search"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr
            >
              <td>{{ item.savings_info.account_no }}</td>
              <td>
                {{ item.savings_info.account_name }}
              </td>
              <td>
                {{ item.time }}
              </td>
              <td>
                {{ formatPrice(parseFloat(item.deposit)+parseFloat(item.withdrawal)) }}
              </td>
<!--              <td>-->
<!--                <div v-if="item.is_void===0">-->
<!--                  <v-icon-->
<!--                    class="mr-2"-->
<!--                    color="error"-->
<!--                    @click="voided(item.savings_info.account_no,item)"-->
<!--                    v-if="is_void_dep"-->
<!--                  >-->
<!--                    {{icons.mdiCashMinus}}-->
<!--                  </v-icon>-->
<!--                  <v-icon-->
<!--                    class="mr-2"-->
<!--                    color="success"-->
<!--                    @click="voided(item.savings_info.account_no,item)"-->
<!--                    v-if="is_void_with"-->
<!--                  >-->
<!--                    {{icons.mdiCashPlus}}-->
<!--                  </v-icon>-->
<!--                </div>-->
<!--              </td>-->
            </tr>
          </template>
        </v-data-table>
        <v-data-table v-if="is_share||is_time" dense
                      :headers="headers_teller_share"
                      :items="teller_data"
                      :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>

              <v-text-field
                class="mx-2"
                :append-icon="icons.mdiAccountSearchOutline"
                label="Search"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr
            >
              <td>{{ item.members.last_name+', '+item.members.first_name+' '+item.members.middle_name }}
              </td>
              <td>
                {{ formatPrice(parseFloat(item.amount)) }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-data-table v-if="is_passbook" dense
                      :headers="headers_teller_passbook"
                      :items="teller_data"
                      :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>

              <v-text-field
                class="mx-2"
                :append-icon="icons.mdiAccountSearchOutline"
                label="Search"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr
            >
              <td>{{ item.savings_info.account_no }}</td>
              <td>
                {{ item.savings_info.account_name }}
              </td>
              <td>
                {{ formatPrice(parseFloat(item.amount)) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_void" width="80%">
      <WithdrawalSlipsRegularSavings :key="key"
                                     v-on:data="on_save_void"
                                     :is_void="true"
                                     :account_noooo="this.account_no"
                                     :amounttt="this.amounttt"
                                     :withdrrww="this.name"
                                     :prop_value="this.void_value"
                                     v-if="is_void_with"
      ></WithdrawalSlipsRegularSavings>
      <DepositSlipsRegularSavings :key="key"
                                  v-on:data="on_save_void"
                                  :is_void="true"
                                  :account_noooo="this.account_no"
                                  :amounttt="this.amounttt"
                                  :depositorrrr="this.depositorrrr"
                                  :prop_value="this.void_value"
                                  v-if="is_void_dep"
      ></DepositSlipsRegularSavings>
    </v-dialog>
  </div>
</template>
<script>

  import {mapActions, mapGetters} from "vuex";
  import depositSlip from '../../load_interface/DepositSlips'
  import payments from '../../payments/Payments'
  import WithdrawalSlipsRegularSavings
    from '../../load_interface/withdrawals_interface/WithdrawalSlipsRegularSavings'
  import DepositSlipsRegularSavings
    from '../../load_interface/deposits_interface/DepositSlipsRegularSavings'
  import {
    mdiPrinter,
    mdiDelete,
    mdiEyeOutline,
    mdiCash100,
    mdiAccountClock,
    mdiCashMinus,
    mdiCashPlus,
    mdiAccountSearchOutline,
    mdiBookOpenPageVariantOutline,
  } from '@mdi/js'

  import {
    mdiAccountCashOutline,
    mdiAccountOutline
  } from "@mdi/js";
  import moment from "moment";

  export default {
    components: {
      depositSlip,
      payments,
      WithdrawalSlipsRegularSavings,
      DepositSlipsRegularSavings,
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiEyeOutline,
          mdiCash100,
          mdiAccountClock,
          mdiCashMinus,
          mdiCashPlus,
          mdiAccountSearchOutline,
          mdiBookOpenPageVariantOutline,
        },
      }
    },
    data() {
      return {
        search: '',
        is_void: false,
        is_void_dep: false,
        is_void_with: false,
        title: '',
        total: '',
        key: 0,
        savings_date: '',
        tab: '',
        tabs: [
          {title: 'PAYMENTS', icon: mdiAccountCashOutline},
          {title: 'DEPOSIT SLIP', icon: mdiAccountOutline},
        ],
        tab_t: '',
        tabs_t: [
          {title: 'SAVINGS', icon: mdiAccountCashOutline},
          {title: 'SHARE CAPITAL', icon: mdiCash100},
          {title: 'TIME DEPOSIT', icon: mdiAccountClock},
          {title: 'PASSBOOK DEPOSIT', icon: mdiBookOpenPageVariantOutline},
        ],
        headers_teller: [
          {text: 'ACCOUNT #', value: 'savings_info.account_no', sortable: false},
          {text: 'ACCOUNT NAME', value: 'savings_info.account_name', sortable: false},
          {text: 'TIME', value: 'time', sortable: false},
          {text: 'AMOUNT', value: 'deposit', sortable: false},
          {text: '', value: '', sortable: false},
        ],
        headers_teller_share: [
          {text: 'NAME', value: 'savings_info.account_name', sortable: false},
          {text: 'AMOUNT', value: 'deposit', sortable: false},
        ],
        headers_teller_passbook: [
          {text: 'ACCOUNT #', value: 'savings_info.account_no', sortable: false},
          {text: 'ACCOUNT NAME', value: 'savings_info.account_name', sortable: false},
          {text: 'AMOUNT', value: 'deposit', sortable: false},
        ],
        teller_transaction_data: false,
        remittances_dialog: false,
        teller_data: [],
        account_no: '',
        amounttt: 0,
        void_value: {},
        depositorrrr: '',

        is_savings: false,
        is_share: false,
        is_time: false,
        is_passbook: false,
      }
    },
    props: {
      deposit: Array,
      withdraw: Array,
      deposit_share: Array,
      withdraw_share: Array,
      deposit_time: Array,
      withdraw_time: Array,
      deposit_passbook: Array,
      withdraw_passbook: Array,
    },
    computed: {
      ...mapGetters('authentication', ['position', 'branch', 'branch_id', 'branch_address', 'branch_contact_no', 'name']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      is_pending_click(index) {
        if (this.position === 'TELLER') {
          this.key++
          this.remittances_dialog = true
          this.savings_date = this.deposit[index].date
        }
      },
      on_save_void() {
        this.teller_transaction_data = false
        this.is_void = false
        this.is_void_dep = false
        this.is_void_with = false
        this.$emit('data')
      },
      voided(account_no, item) {
        this.depositorrrr = item.depositors_or_withdrawals_name
        this.void_value = item
        this.account_no = account_no
        this.amounttt = (parseFloat(item.deposit) + parseFloat(item.withdrawal))
        this.key++
        this.is_void = true
      },
      selected_data_deposit(index) {
        if (this.position === 'BRANCH MANAGER' || this.position === 'ADMIN') {
          this.title = 'DEPOSITS as of ' + this.deposit[index].date
          this.teller_transaction_data = true
          this.total = this.deposit[index].amount
          this.teller_data = this.deposit[index].deposits_data
          this.is_void_dep = true
          this.is_void_with = false
          this.key++
          this.is_savings = true
          this.is_share = false
          this.is_time = false
          this.is_passbook = false
        }
      },
      selected_data_withdrawal(index) {
        if (this.position === 'BRANCH MANAGER' || this.position === 'ADMIN') {
          this.title = 'WITHDRAWALS as of ' + this.withdraw[index].date
          this.teller_transaction_data = true
          this.total = this.withdraw[index].amount
          this.teller_data = this.withdraw[index].withdrawals_data
          this.is_void_dep = false
          this.is_void_with = true
          this.key++
          this.is_savings = true
          this.is_share = false
          this.is_time = false
          this.is_passbook = false
        }
      },
      selected_data_deposit_share(index) {
        if (this.position === 'BRANCH MANAGER' || this.position === 'ADMIN') {
          this.title = 'DEPOSITS SHARES as of ' + this.deposit_share[index].date
          this.teller_transaction_data = true
          this.total = this.deposit_share[index].amount
          this.teller_data = this.deposit_share[index].deposits_data
          this.is_void_dep = true
          this.is_void_with = false
          this.key++
          this.is_savings = false
          this.is_share = true
          this.is_time = false
          this.is_passbook = false
        }
      },
      selected_data_withdrawal_share(index) {
        if (this.position === 'BRANCH MANAGER' || this.position === 'ADMIN') {
          this.title = 'WITHDRAWALS SHARES as of ' + this.withdraw_share[index].date
          this.teller_transaction_data = true
          this.total = this.withdraw_share[index].amount
          this.teller_data = this.withdraw_share[index].withdrawals_data
          this.is_void_dep = false
          this.is_void_with = true
          this.key++
          this.is_savings = false
          this.is_share = true
          this.is_time = false
          this.is_passbook = false
        }
      },
      selected_data_deposit_time(index) {
        if (this.position === 'BRANCH MANAGER' || this.position === 'ADMIN') {
          this.title = 'DEPOSITS TIME as of ' + this.deposit_time[index].date
          this.teller_transaction_data = true
          this.total = this.deposit_time[index].amount
          this.teller_data = this.deposit_time[index].deposits_data
          this.is_void_dep = true
          this.is_void_with = false
          this.key++
          this.is_savings = false
          this.is_share = false
          this.is_time = true
          this.is_passbook = false
        }
      },
      selected_data_withdrawal_time(index) {
        if (this.position === 'BRANCH MANAGER' || this.position === 'ADMIN') {
          this.title = 'WITHDRAWALS TIME as of ' + this.withdraw_time[index].date
          this.teller_transaction_data = true
          this.total = this.withdraw_time[index].amount
          this.teller_data = this.withdraw_time[index].withdrawals_data
          this.is_void_dep = false
          this.is_void_with = true
          this.key++
          this.is_savings = false
          this.is_share = false
          this.is_time = true
          this.is_passbook = false
        }
      },
      selected_data_deposit_passbook(index) {
        if (this.position === 'BRANCH MANAGER' || this.position === 'ADMIN') {
          this.title = 'DEPOSITS PASSBOOK as of ' + this.deposit_passbook[index].date
          this.teller_transaction_data = true
          this.total = this.deposit_passbook[index].amount
          this.teller_data = this.deposit_passbook[index].deposits_data
          this.is_void_dep = true
          this.is_void_with = false
          this.key++
          this.is_savings = false
          this.is_share = false
          this.is_time = false
          this.is_passbook = true
        }
      },
      selected_data_withdrawal_passbook(index) {
        if (this.position === 'BRANCH MANAGER' || this.position === 'ADMIN') {
          this.title = 'WITHDRAWALS PASSBOOK as of ' + this.withdraw_passbook[index].date
          this.teller_transaction_data = true
          this.total = this.withdraw_passbook[index].amount
          this.teller_data = this.withdraw_passbook[index].withdrawals_data
          this.is_void_dep = false
          this.is_void_with = true
          this.key++
          this.is_savings = false
          this.is_share = false
          this.is_time = false
          this.is_passbook = true
        }
      },
      print_selected_deposit_slip_data() {
        var imgData = this.company_logo
        var payments_array = []
        var date = this.title
        var total_net = this.total
        var table_with = []
        if (this.is_savings) {
          table_with = [160, 160, 80, 100]
          payments_array.push(
            [
              {text: 'ACCOUNT #', alignment: 'center', style: 'label'},
              {text: 'ACCOUNT NAME', alignment: 'center', style: 'label'},
              {text: 'TIME', alignment: 'center', style: 'label'},
              {text: 'AMOUNT', alignment: 'center', style: 'label'},
            ]
          )
          if (this.teller_data.length > 0) {
            this.teller_data.forEach(function (item, index, payment) {
              payments_array.push(
                [
                  {text: item.savings_info.account_no, alignment: 'center'},
                  {text: item.savings_info.account_name, alignment: 'center'},
                  {text: item.time, alignment: 'center'},
                  {text: parseFloat(item.deposit) + parseFloat(item.withdrawal), alignment: 'left'},
                ]
              )
              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push(
                  [
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {text: '', alignment: 'left', border: [false, false, false, false]},
                    {
                      text: 'Total:',
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: total_net,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ]
                )
              }
            })
          } else {
            payments_array.push(
              [
                '----',
                '----',
                '----',
                '----',
              ]
            )
          }
        } else if (this.is_share||this.is_time) {
          table_with = [160, 100]
          payments_array.push(
            [
              {text: 'NAME', alignment: 'center', style: 'label'},
              {text: 'AMOUNT', alignment: 'center', style: 'label'},
            ]
          )
          if (this.teller_data.length > 0) {
            this.teller_data.forEach(function (item, index, payment) {
              payments_array.push(
                [
                  {
                    text: item.members.last_name + ', ' + item.members.first_name + ' ' + item.members.middle_name,
                    alignment: 'center'
                  },
                  {text: parseFloat(item.amount), alignment: 'left'},
                ]
              )
              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push(
                  [
                    {
                      text: 'Total:',
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: total_net,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ]
                )
              }
            })
          } else {
            payments_array.push(
              [
                '----',
                '----',
              ]
            )
          }
        }else if (this.is_passbook) {
          table_with = [160, 230,100]

          payments_array.push(
            [
              {text: 'ACCOUNT #', alignment: 'center', style: 'label'},
              {text: 'ACCOUNT NAME', alignment: 'center', style: 'label'},
              {text: 'AMOUNT', alignment: 'center', style: 'label'},
            ]
          )
          if (this.teller_data.length > 0) {
            this.teller_data.forEach(function (item, index, payment) {
              payments_array.push(
                [

                  {
                    text: item.savings_info.account_no,
                    alignment: 'center'
                  },
                  {
                    text: item.savings_info.account_name,
                    alignment: 'center'
                  },
                  {text: parseFloat(item.amount), alignment: 'left'},
                ]
              )
              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push(
                  [
                    {
                      text: '',
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: 'Total:',
                      alignment: 'right',
                      border: [false, false, false, false],
                    },
                    {
                      text: total_net,
                      alignment: 'left',
                      border: [false, false, false, false],
                    },
                  ]
                )
              }
            })
          } else {
            payments_array.push(
              [
                '----',
                '----',
                '----',
              ]
            )
          }
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'TELLER TRANSACTIONS DATA', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: date, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: table_with,
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Collector', style: 'aa_placeholder'},
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'cs_top_margin'},
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cs_for'},
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cs_line'},
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Collection Supervisor', style: 'cs_placeholder'},
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
