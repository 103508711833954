<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LOAD DEPOSIT SLIPS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            ></v-col>
            <v-row>
              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="date_of_deposit"
                  label="Date Deposited"
                  dense
                  outlined
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>

              <v-col
                md="6"
                cols="12"
              >
                <v-text-field
                  v-model="time_of_deposit"
                  label="Time Deposited"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  @keyup="time_release_format"
                  maxlength="8"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                md="6"
                cols="12"
              >
                <v-select
                  v-model="bank_id"
                  dense
                  outlined
                  label="Bank Code"
                  :items="banks"
                  item-value="id"
                  item-text="bank_code_name"
                  :rules="rules.combobox_rule"
                  @change="selected_bank(banks[banks.map(function (x) {
              return x.id
            }).indexOf(bank_id)])"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="bank_account_no"
                  label="Bank Account #"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="amount"
                  label="Amount"
                  dense
                  outlined
                  type="number"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="collector_id"
                  dense
                  outlined
                  label="Collector"
                  :items="collectors"
                  item-value="id"
                  item-text="full_name"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
            </v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_deposit_slips"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.init()
    },
    data() {
      return {
        bank_account_no: '',

        saving: false,
        alert: false,
        alert_message: '',

        collectors: [],
        collector_id: 0,
        banks: [],
        bank_id: 0,
        date_of_deposit: '',
        time_of_deposit: '',
        amount: 0,
        payment_for: 'All',
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'month_of', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('staff', ['search_staff_by_category']),
      ...mapActions('bank_depositories', ['get_list_of_bank_depository']),
      ...mapActions('deposit_slips', ['save_new_deposit_slips']),
      time_release_format() {
        this.time_of_deposit = this.time_formatter(this.time_of_deposit)
      },
      time_formatter(time_in) {
        return time_in = time_in.replace(/[^0-9]/g, '')
          .replace(/^(\d{2})?(\d{2})?(\d{2})/g, '$1:$2:$3')
      },
      init() {
        const data = new FormData()
        data.append('category', 'positions');
        data.append('posi', this.position);
        data.append('text', 'Collector');
        data.append('branch_id', this.branch_id);
        this.search_staff_by_category(data)
          .then(response => {
            this.collectors = response.data
          })
          .catch(error => {
            console.log(error)
          })
        const data2 = new FormData()
        data2.append('category', 'active');
        data2.append('text', '0');
        this.get_list_of_bank_depository(data2)
          .then(response => {
            this.banks = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.bank_account_no = ''
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.collectors = []
        this.collector_id = 0
        this.banks = []
        this.bank_id = 0
        this.date_of_deposit = ''
        this.time_of_deposit = ''
        this.amount = 0
      },
      selected_bank(data) {
        this.bank_account_no = data.account_no;
      },
      save_deposit_slips() {
        this.saving = true
        this.alert = false
        // var flag = false
        // if (moment(this.date_of_deposit, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
        //   || moment(this.date_of_deposit, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
        //   flag = true
        // } else {
        //   var compareDate = moment(this.date_of_deposit, 'YYYY-MM-DD')
        //   var startDate = moment(this.month_start, 'MMMM D, YYYY')
        //   var endDate = moment(this.month_end, 'MMMM D, YYYY')
        //
        //   flag = compareDate.isBetween(startDate, endDate) //false in this case
        // }
        // if (flag === false) {
        //   this.date_of_deposit = ''
        //   this.alert = true
        //   this.alert_message = 'Date Deposited is Not Belong in Transaction Month!'
        //   this.saving = false
        // } else {
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('date_of_deposit', moment(this.date_of_deposit, 'YYYY-MM-DD').format('MMMM D, YYYY'));
          data.append('time_of_deposit', this.time_of_deposit);
          data.append('bank_id', this.bank_id);
          data.append('amount', this.amount);
          data.append('collector_id', this.collector_id);
          data.append('payment_for', 'All');
          data.append('month_of', this.month_of);
          this.save_new_deposit_slips(data)
            .then(response => {
              if (response.data === 'DEPOSIT SLIP LOADED SUCCESSFULLY') {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
              } else {
                this.change_snackbar({
                  show: true,
                  color: 'error',
                  text: response.data,
                })
              }
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
        // }
      }
    }
  }
</script>
